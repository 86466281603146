<template>
  <div class="col-12">
    <br>
    <div class="card">
        <div class="card-header">
            <h3 id='ffff'>
                تقرير غياب الطلاب بتاريخ
                <u id="date"></u>
            </h3>
            <b-dropdown
            id="dropdown-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="🛠️ خيارات"
            variant="success"
            >
              <b-dropdown-item @click="send()">
                  <i class="fa fa-comment"></i>
                  ارسال رسالة للغائبين
              </b-dropdown-item><hr>
              <b-dropdown-item @click="noorModal()">
                  <i class="fa fa-paper-plane"></i>
                  اضافة الغياب لنور
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
            id="dropdown-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="📥 طباعة / تصدير"
            variant="primary"
            >
              <b-dropdown-item @click="printDiv()">
                  <i class="fa fa-print"></i>
                  طباعة التقرير
              </b-dropdown-item><hr>
              <b-dropdown-item @click="exportToExcel()">
                  <i class="fa fa-download"></i>
                  تصدير التقرير اكسل
              </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="card-body">احتساب غياب كلي اذا تجاوز <input type="number" onclick="this.select()" @change="refreshStudnets()" @keyup="refreshStudnets()" style="border: 2px solid #999; width: 30px; border-radius: 5px; text-align: center" v-model="ghiab_limit"> حصة
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm" id="headerTable"> <div id="date2"></div>
                    <thead>
                        <th>
                            م
                        </th>
                        <th>
                            الطالب
                        </th>
                        <th>
                            الفصل
                        </th>
                        <th>
                            الحصص
                        </th>
                        <th>
                            الحالة
                        </th>
                    </thead>
                    <tbody>
                        <template v-for="(student, index) in students">
                            <tr :key="student.name" v-if="student.show" :class="bgs[student.status - 1]">
                                <td>
                                    {{ index + 1 }}
                                </td>
                                <td>
                                    {{ student.name }}
                                </td>
                                <td>
                                    {{ student.classname }}
                                </td>
                                <td>
                                    <template v-for="(lesson, i) in student.lessons">
                                        <button @click="toggleLessonStatus(student, lesson)" :key="i + student.name" :class="'btn btn-sm text-white ' + (lesson.status == 1 ? 'bg-success' : 'bg-danger')" style='border-radius: 0px; padding: 5px'>
                                            <strong v-if="lesson.status == 1" class="fa fa-checks">√</strong>
                                            <strong v-if="lesson.status == 2" class="fa fa-timess">X</strong>
                                            {{ lesson.lesson }}
                                        </button>
                                    </template>
                                </td>
                                <td @click="toggleDayStatus(student)">
                                    <span class="btn btn-sm btn-success" style="border-radius: 0px; padding: 5px" v-if="student.status == 1">
                                        <i class="fa fa-check"></i>
                                        حاضر
                                    </span>
                                    <span class="btn btn-sm btn-danger" style="border-radius: 0px; padding: 5px" v-if="student.status == 2">
                                        <i class="fa fa-times"></i>
                                        غياب كلي
                                    </span>
                                    <span class="btn btn-sm btn-warning" style="border-radius: 0px; padding: 5px" v-if="student.status == 3">
                                        <i class="fa fa-info"></i>
                                        غياب جزئي
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="modal-students-ghiab-manual" size="lg" title="ارسال رسائل لاولياء الامور الغائبين" hide-footer>
        <div class="col-12 text-center g">
            <div class="alert alert-success text-center g" v-if="view_archive">
                <img src="/files/done.gif" style="border-radius: 50%">
                <br>
                <br>
                تم استلام الرسائل بنجاح وجاري ارسالها الآن٫<br>
                يمكنك متابعة الارسال <a :href="`/archive?school_id=${school_id}`" target="_blank"><strong><u>من هنا</u></strong></a>
            </div>
            <template v-if="!loading && !view_archive">
                <button class="btn btn-lg btn-primary" v-if="selected == 'sms' || selected == 'whatsapp-api'" @click="sendNow(selected)">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
                <div class="col-12 text-center alert alert-success" v-if="selected == 'whatsapp'" id='remove-me-wa'>
                    <h4>
                        لتتمكن من ارسائل رسائل الواتسآب، يجب عليك تحميل أداة الخدمات الاضافية من سوق كروم.
                        <br><br>
                        <a href="https://bit.ly/3yAC50a" target="_blank" class="btn btn-success">
                            <i class="fa fa-download"></i>
                            إضغط هنا لتثبيت الاداة
                        </a>
                        <br>
                        وبعد تثبيتها قم بإعادة تحديث هذه الصفحة.
                    </h4>
                </div>
                <button class="btn btn-lg btn-primary" v-if="selected == 'whatsapp'" @click="sendNow(selected)" id="brmja-send">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
            </template>
            <template v-if="loading && !view_archive">
                <button class="btn btn-lg btn-primary disabled">
                    جاري الإرسال...
                </button>
            </template>
        </div>
        <div class="form-group">
          <label for="">ارسال الى</label>
          <select class="form-control" v-model="sendto">
            <option value="all">الكل</option>
            <option value="2">الغياب الكلي</option>
            <option value="3">الغياب الجزئي</option>
          </select>
        </div>
        <div class="form-group">
          <label for="">محتوى الرسالة</label>
          <textarea class="form-control" @keyup="messageChanged()" @change="messageChanged()" v-model="message" rows="5"></textarea>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover" id="brmja-messages-table">
                <thead>
                    <th>
                        الجوال
                    </th>
                    <th>
                        الطالب
                    </th>
                    <th>
                        الرسالة
                    </th>
                </thead>
                <tbody id="students-messages">
                    <template v-for="(student, c) in students">
                      <tr v-if="student.show && student.status == 2 && (sendto == 'all' || sendto == '2') || student.show && student.status == 3 && (sendto == 'all' || sendto == '3')" :key="c">
                          <td>{{ student.phone }}</td>
                          <td>{{ student.name }}</td>
                          <td><textarea class='form-control msg' readonly v-model="student.message"></textarea></td>
                      </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </b-modal>
    <button
      class="dnone"
      id="students-ghiab-manual"
      @click="sendModal()"
      v-b-modal.modal-students-ghiab-manual
    ></button>

    <b-modal id="modal-students-ghiab-manual2" size="lg" title="اضافة الغياب لنور" hide-footer>
         <div class="col-12 text-center g addGhiabBTN">
              <button class="btn btn-lg btn-success day" @click="addGhiab()" style="display: inline-block;">إضافة الغياب الآن</button>
              <br><br>
              <div class="alert alert-warning g">
                  هذه الخدمة تجريبية، يمكنك تجربتها وإذا واجهتك أي مشكلة قم بالتواصل مع الدعم الفني، وشكراً.
              </div>
              <hr>
          </div>
          <div class="col-12 text-center g">
              <div class="form-group">
                  <label for="">نوع حساب نور (المرحلة)</label>
                  <select class="form-control form-control-lg" style="border: solid 2px red;" v-model="stage">
                      <option value="mt">متوسط</option>
                      <option value="eb">ابتدائي</option>
                      <option value="mas">مسارات</option>
                  </select>
              </div>
              <div class="form-group">
                  <label for="">تغيير الكل إلى:</label>
                  <select class="form-control form-control-lg" style="border: solid 2px red;" @change="changeAllNoorStatuses()" v-model="changeAllNoorStatuses_value">
                      <option value="-99">-- اختر --</option>
                      <option value="48,">الغياب بدون عذر مقبول عن كل يوم دراسي خلال الفصل الدراسي </option>
                      <option value="1201033,">الغياب بعذر </option>
                  </select>
              </div>
          </div>
          <div class="col-12 table-responsive">
              <table class="table table-hover table-striped table-bordered">
                  <thead>
                      <tr style="background: rgb(255, 255, 255);"><th>
                          الطالب
                      </th>
                      <th>
                          نوع المخالفة
                      </th>
                  </tr>
                  </thead>
                  <tbody class="noorStudents">
                    <template v-for="(student, c) in students">
                      <tr v-if="student.show && student.status == 2" :key="c">
                          <td>{{ student.name }}</td>
                          <td>
                            <select style="height: 30px; max-width: 200px;" v-model="student.ghiab">
                              <option value="-99">-- اختر --</option>
                              <option value="48,">الغياب بدون عذر مقبول عن كل يوم دراسي خلال الفصل الدراسي </option>
                              <option value="1201033,">الغياب بعذر </option>
                            </select>
                          </td>
                      </tr>
                    </template>
                  </tbody>
              </table>
          </div>
    </b-modal>
    <button
      class="dnone"
      id="students-ghiab-manual2"
      v-b-modal.modal-students-ghiab-manual2
    ></button>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            students: [],
            n_students: [],
            bgs: ["", "bg-light-danger", "bg-light-warning"],
            school_id: localStorage.getItem("school_id"),
            message: "ابنكم {name} غائب الحصص التالية {lessons}",
            view_archive: false,
            selected: "",
            loading: false,
            changeAllNoorStatuses_value: "-99",
            stage: "mt",
            ghiab_limit: 7,
            sendto: "all"
        }
    },
    created(){
        var g = this;
        var naqar_get = function(t){
        return new Promise((resolveOuter) => {
            $("body").append(`<textarea class='get-local-storage' name='${t}'></textarea>`)
            var i111 = setInterval(function(){
                if($(".get-local-storage-value[name='"+t+"']").length){
                    clearInterval(i111)
                    resolveOuter($(".get-local-storage-value[name='"+t+"']").val())
                }
            })
        });
        }
        setTimeout(() => {
            naqar_get("data").then(function(data){

                naqar_get("n_students_" + g.school_id).then(function(d){
                    if(!d || d == "null" || d == "undefined"){
                        alert("لضمان اضافة الغياب وارسال الرسائل برجاء تحديث بيانات الطلاب من نور للاداة \n من خلال الاداة في مدرستي اضغط على خدمات الطلاب واضغط على تحديث بيانات الطلاب من نور.")
                    }else{
                        g.n_students = JSON.parse(d)
                    }

                var result = { data: JSON.parse(data) }

                $.each(result.data[1].students, function (i, student) {

                    var s = {
                            lessons: [],
                            lessons2: [],
                            name: student.name,
                            classname: student.title,
                            status: 2,
                            show: true,
                            ghiab_status: "-99",
                            phone: ""
                        }

                    $.each(result.data[0].data, function (i2, data) {

                        if (data.name == student.name) {

                            if (data.status == 'حاضر') {

                                if (!s.lessons2.includes(data.lesson)) {
                                    s.lessons.push({
                                        lesson: data.lesson,
                                        status: 1
                                    })
                                    s.lessons2.push(data.lesson)
                                }

                            } else {

                                if (!s.lessons2.includes(data.lesson)) {
                                    s.lessons.push({
                                        lesson: data.lesson,
                                        status: 2
                                    })
                                    s.lessons2.push(data.lesson)
                                }

                            }

                        }

                    });

                    g.students.push(s)

                });

                g.refreshStudnets()
                g.fromNoor()


                })

            })
        }, 500)
    },
    methods: {  printDiv() {
            document.getElementById("date2").innerHTML = document.getElementById("ffff").innerHTML
            var divToPrint=document.getElementById("headerTable");
            var newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML + `<style>
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                white-space: nowrap;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                button{
                    background: none;
                    border:none
                }
                </style>`);
            newWin.document.close();
            newWin.print();
        },
        exportToExcel() {
            var location = 'data:application/vnd.ms-excel;base64,';
            var excelTemplate = '<html> ' +
                '<head> ' +
                '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
                '</head> ' +
                '<body> ' +
                document.getElementById("headerTable").outerHTML +
                '</body> ' +
                '</html>'
            window.location.href = location + btoa(unescape(encodeURIComponent((excelTemplate))));
        },
        toggleLessonStatus(student, lesson){
            lesson.status = lesson.status == 1 ? 2 : 1
            this.refreshStudnets()
        },
        toggleDayStatus(student){
            student.status = student.status == 1 ? 2 : (student.status == 2 ? 3 : student.status == 3 ? 1 : 0)
            this.refreshStudnets()
        },
        refreshStudnets(){
            var g = this;
            var arr = []
            g.students.forEach(function(a){
                var status_1 = false, status_2 = false, c = 0
                a.lessons.forEach(function(lesson){
                    if(lesson.status == 1){
                        status_1 = true
                    }
                    if(lesson.status == 2){
                        status_2 = true
                        c++
                    }
                })
                if(status_1 && !status_2){
                    a.status = 1
                }
                if(!status_1 && !status_2){
                    a.status = 2
                }
                if(!status_1 && status_2){
                    a.status = 2
                }
                if(status_1 && status_2){
                    a.status = 3
                }
                if(c >= g.ghiab_limit){
                    a.status = 2
                }
                arr.push(a)
            })
            g.students = arr
        },
        addGhiab(){
          var g = this;
          $("body").append(`
            <textarea class='save-me-to-local-storage' name='stage'>${g.stage}</textarea>
          `)
          var i1 = setInterval(() => {
            if(!$(".save-me-to-local-storage[name='stage']").length){
              clearInterval(i1)
              $("body").append(`
                <textarea class='save-me-to-local-storage' name='ghiab-type'>day</textarea>
              `)
              var i2 = setInterval(() => {
                if(!$(".save-me-to-local-storage[name='ghiab-type']").length){
                  clearInterval(i2)
                  $("body").append(`
                    <textarea class='save-me-to-local-storage' name='ghiab-students'>${JSON.stringify(g.students.map(function(a){if(a.show){return {name: a.name, status: a.ghiab_status}}}).filter((x)=>{return x}))}</textarea>
                  `)
                  var i3 = setInterval(() => {
                    if(!$(".save-me-to-local-storage[name='ghiab-students']").length){
                      clearInterval(i3)
                      if(confirm('برجاء الذهاب لصفحة "إدخال السلوك والمواظبة" في نور' + '\n هل تريد الذهاب لنور الان؟')){
                        window.open('https://noor.moe.gov.sa/');
                      }
                    }
                  }, 100);
                }
              }, 100);
            }
          }, 100);
        },
        send(){
            $("#c-subscribe-ended").click()
            $("#after-send-settings-click").val("#students-ghiab-manual")
        },
        sendModal(){
          var g =this;
          if(localStorage.getItem('send')){
              g.selected = JSON.parse(localStorage.getItem('send')).selected
          }
          g.messageChanged()
        },
        messageChanged(){
          var g = this;
          g.students = g.students.map(function(student){
            var lessons = []
            student.lessons.forEach(function(a){
                if(a.status == 2){
                    lessons.push(a.lesson)
                }
            })
            student.message = g.message.replace("{name}", student?.name).replace("{lessons}", lessons.join(",")).replace("{date}", new Date().toISOString().split("T")[0])
            return student;
          })
          g.refreshStudnets()
        },
        sendNow(method){
            var g = this;
            var c = []
            g.students.forEach(function(s){
              if(s.show && s.status == 2 || s.show && s.status == 3){
                c.push({
                  phone: s.phone,
                  message: s.message
                })
              }
            })
            g.loading = true
            $.post(api + '/api/messages/send', {
                school_id: g.school_id,
                arr: JSON.stringify(c),
                method: method,
                settings: localStorage.getItem('send'),
                type: 'students'
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.view_archive = true
                }else{
                    alert(response.response)
                }
            })
            .fail(function(){
                g.loading = false
                alert('حدث خطأ')
            })
        },
        noorModal(){
          var g = this;
          $("#students-ghiab-manual2").click()
        },
        changeAllNoorStatuses(){
          var g = this;
          g.students = g.students.map(function(a){
            a.ghiab = g.changeAllNoorStatuses_value
            return a;
          })
          g.refreshStudnets()
        },
        editDistance: (s1, s2) => {
        s1 = s1.toLowerCase();
        s2 = s2.toLowerCase();

        var costs = new Array();
        for (var i = 0; i <= s1.length; i++) {
        var lastValue = i;
        for (var j = 0; j <= s2.length; j++) {
            if (i == 0)
            costs[j] = j;
            else {
            if (j > 0) {
                var newValue = costs[j - 1];
                if (s1.charAt(i - 1) != s2.charAt(j - 1))
                newValue = Math.min(Math.min(newValue, lastValue),
                    costs[j]) + 1;
                costs[j - 1] = lastValue;
                lastValue = newValue;
            }
            }
        }
        if (i > 0)
            costs[s2.length] = lastValue;
        }
        return costs[s2.length];
    },
        fromNoor(){
            var g = this, arr = [];
            g.students.forEach(function(m){
                var percent = 0.5, nn = {}
                g.n_students.forEach(function(n){
                    if(g.similarity(m.name , n.name) > percent){
                        percent = g.similarity(m.name, n.name)
                        nn = n
                    }
                })
                if(nn.name){
                    m.name = nn.name
                    m.phone = nn.phone
                }
                arr.push(m)
            })
            g.students = arr
        },
        similarity: function(s1, s2){
            var longer = s1;
            var shorter = s2;
            if (s1.length < s2.length) {
            longer = s2;
            shorter = s1;
            }
            var longerLength = longer.length;
            if (longerLength == 0) {
            return 1.0;
            }
            return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
        },
    }
}
</script>

<style>
td, thå {
    white-space: nowrap;
}
</style>
<style>
#date2{
    display: none;
}
.b-dropdown.show{
  width: 300px
}
</style>